import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getNewLeaderboard } from 'api/leaderboard';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, CircularProgress, Stack, Tab, Tabs } from '@mui/material';

const LowerLeaderboard: React.FC = () => {
    const [tab, setTab] = useState(0);
    const { isLoading, data } = useQuery('leaderboard-curators', getNewLeaderboard);

    const columns: GridColDef[] = [
        {
            field: 'place',
            headerName: 'Место',
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'player',
            headerName: 'Место',
            flex: 0.3,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'abi',
            headerName: 'ABI',
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => params.value.toFixed(1),
        },
        {
            field: 'ts_count',
            headerName: 'Входы',
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'roi',
            headerName: 'ROI',
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => (params.value * 100).toFixed(0),
        },
        {
            field: 'ev',
            headerName: 'EV',
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => params.value.toFixed(1),
        },
        {
            field: 'days_count',
            headerName: 'Дни',
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'score',
            headerName: 'Очки',
            flex: 0.1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => params.value.toFixed(1),
        },
    ];

    return (
        <div className="flex flex-col items-center">
            <p className="text-xl text-gray-700 font-medium mb-1">Нижний лидерборд (до АБИ15)</p>
            {/* <p className="text-l text-gray-700 font-medium mb-3">Дата обновления: {moment(data?.updated_at).format('DD.MM.YYYY')}</p> */}
            <div className="shadow-md alternate-table mb-8 bg-white rounded overflow-hidden w-full" style={{ maxWidth: '1100px' }}>
                {isLoading && (
                    <div className="flex items-center justify-center p-4">
                        <CircularProgress size={20} />
                    </div>
                )}
                {!isLoading && (
                    <DataGrid
                        autoHeight={true}
                        rows={data.lower}
                        columns={columns}
                        disableSelectionOnClick
                        disableColumnMenu
                        pagination
                        hideFooter
                        getRowId={row => `${row.place} ${row.group}`}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Данные отсутствуют
                                </Stack>
                            ),
                        }}
                    />
                )}
            </div>
            <p className="text-xl text-gray-700 font-medium mb-2">Информация</p>
            <div className="w-full rounded shadow-md bg-white overflow-hidden mb-8">
                <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} className="border-b">
                    <Tab label="Правила" />
                    <Tab label="Призы" />
                </Tabs>
                <div className="p-5 text-gray-700">
                    <div hidden={tab !== 0}>
                        <p><b>Лидерборд KOT-MTT: Начисление Очков и Механика Расчёта</b></p>
                        <br />
                        <p><b>Общий принцип:</b></p>
                        <p>Ваши достижения будут сравниваться с базовым уровнем, который определён как результаты нижних 25% участников. Превосходство над этим уровнем приносит вам дополнительные очки, а показатели ниже этой отметки уменьшают ваш итоговый счёт.</p>

                        <p><b>Ключевые показатели для начисления очков:</b></p>
                        <ul className="list-disc list-inside pl-5">
                            <li>Входы: Количество турниров, сыгранных с начала лидерборда.</li>
                            <li>ROI: ROI% в турнирах, сыгранных с начала лидерборда.</li>
                            <li>EV: Средний EV в стеках от 15 до 60 больших блайндов, рассчитанный с начала лидерборда.</li>
                        </ul>

                        <p><b>Механика расчёта:</b></p>
                        <p>Ваши результаты в каждом из показателей сравниваются с результатами 75-го процентиля (нижние 25%). Превышение этих результатов означает получение баллов, а их недостижение — потерю очков. Каждый показатель влияет на итоговый счёт, который также корректируется в зависимости от времени, прошедшего с начала лидерборда.</p>

                        <p><b>Пример расчёта:</b></p>
                        <p>Предположим, что с начала лидерборда прошло n дней. Ваши показатели по количеству турниров, ROI и EV сравниваются с базовым уровнем, установленным исходя из результатов нижней четверти участников. Очки начисляются или вычитаются в зависимости от вашего относительного преимущества или отставания.</p>

                        <p><b>Стратегия для успеха:</b></p>
                        <p>Для успеха на лидерборде требуется не только активное участие в турнирах (гринд), но и стремление к максимально качественной игре в каждом турнире, чтобы превосходить базовый уровень и увеличивать свои шансы на выигрыш.</p>
                        <br/>
                        <p><b>Минимальное количество турниров для борьбы за призовые места - 1200 турниров за срок проведения лидерборда.</b></p>
                        <p><b>Сроки проведения:  15 июля - 31 октября (включительно).</b></p>
                    </div>
                    <div hidden={tab !== 1}>
                        <p>Нижний лидерборд:</p>
                        <br />
                        <li>1 место - $1500 + Фирменное худи и кепка</li>
                        <li>2 место - $1000 + Фирменное худи и кепка</li>
                        <li>3 место - $700 + Фирменное худи и кепка</li>
                        <li>4 место - $500 + Фирменное худи</li>
                        <li>5 место - $350 + Фирменное худи</li>
                        <li>6 место - $275 + Фирменная кепка</li>
                        <li>7 место - $200 + Фирменная кепка</li>
                        <li>8 место - $175 + Фирменная кепка</li>
                        <li>9 место - $160 + Фирменная кепка</li>
                        <li>10 место - $140 + Фирменная кепка</li>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LowerLeaderboard;
